@charset "UTF-8";

@import "../../utilities/variables";
@import "../../utilities/mixins";
@import "../../support/slick";

/*
// _modal.scss 이동

.ui_modal_wrap {
    &.init-type {
        display: flex;
        margin:0;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        background: rgba(0,0,0,0.7);

        .popup-wrap {
            position: relative;
            height: 660px;
            max-height:none;
            border-radius:8px;

            &.small{
                left: inherit !important;
                top: initial !important;
                transform: initial !important;
                height: auto; 
                .pop-conts {
                    padding: 32px 16px 40px;
                }  
    
                .pop-footer {
                    .btn-close {
                        min-width:100px;
                        padding: 15px 0;
                        font-size: 16px;
                        line-height: 24px;
                        border-radius: 30px;
        
                    }
                }
            }

            &.active + .active {
                margin-left:24px;
            }

            .pop-conts {
                overflow:auto;
                height:calc(100% - 78px - 116px);

                &.support {
                    > .mCustomScrollBox {
                        > .mCSB_container {
                            padding: 0 40px;
                        }
                    }
                    @include mobile {
                        padding: 0 16px;
                    }
                }
                &.mCustomScrollbar {
                    overflow:hidden;
                    padding:0;
                    > .mCustomScrollBox {
                        > .mCSB_container {
                           padding:48px 40px 0
                        }
                        > .mCSB_scrollTools {
                           right:10px;
                        }
                    }
                }
            }
            
            .img-box {
                a {
                    display: block;
                }

                .pc-img {
                    display: block;
                    @include mobile {
                        display: none;
                    }
                }
                .mo-img {
                    display: none;

                    @include mobile {
                        display: block;
                    }
                }
                img {
                    width:100%;
                }
            }
            
            .headline {
                margin-bottom:24px;
                line-height:1.63;
                &+.text-cont {
                    margin-top:32px;
                }
            }
            .text-cont {
                // margin-top:32px;
                font-size:16px;
                line-height:1.63;
            }
                
            .video-container {
                .video-box {
                    position: relative;
                    height:0;
                    padding-top:56.25%;
                    margin-bottom:32px;
                    iframe, video {
                        position: absolute;
                        top:0;
                        left:0;
                        width:100%;
                        height:100%;
                    }
                }
                .video-tit {
                    font-size:24px;
                    font-weight:bold;
                    color:#000;
                    line-height:1.42;
                }
    
                .video-figure {
                    overflow:auto;
                    height:160px;
                    margin-top:15px;
                    padding:3px 0;
                    border:1px solid #ddd;
                    border-radius:10px;
                    box-sizing:border-box;

                    &.mCustomScrollBox {
                        overflow:hidden;
                    }
    
                    .figure-inner {
                        padding:24px;
                        font-size:14px;
                        line-height:1.71;
                        word-break: break-all;
                        white-space: pre-line;
                    }
                }

            }
            .btn-wrap {
                font-size:0;
                margin-top:24px;
                text-align: center;

                .btn {
                    margin:0 4px;
                }
            }

            .btn {
                &.full {
                    width:100%;
                    max-width:none;
                }
            }
        }
        .pop-footer {
            padding:26px 40px 40px;

            &.check-type{
                input[type=checkbox] + label::after{
                    top:52%;
                }
            }
        }

    }
    @include tablet {
        &.init-type {
            overflow:auto;
            padding:40px;

            &.center-only {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
    
            .popup-wrap {
                height:506px;
                margin:0 auto;

                &.active + .active {
                    margin-left:auto;
                    margin-top:24px;
                }

                .pop-conts {
                    height:calc(100% - 78px - 76px);
                    &.mCustomScrollbar {
                        padding:0;
    
                        > .mCustomScrollBox > .mCSB_container {
                            padding:40px 40px 10px
                        }
                    }
                }

                .video-container {
                    .video-figure {
                        height:160px;
                        margin-top:12px;
                    }
    
                }
             
                .pop-footer {
                    padding:10px 16px;
                    .btn-close {
                        min-width:100px;
                        padding: 15px 0;
                        font-size: 16px;
                        line-height: 24px;
                        border-radius: 30px;
                    }
                }
            }
        }
    }

}
*/

.tablet-only { display: none; }
@include tablet {
    .tablet-only {
        display: block;
    }
}

.support-home {
    min-width: 768px;
    section {
       &.section {
        padding:0;

        &~.section {
            border-color: #f8f8f8;
        }
       } 
       //BTOCSITE-70096 고객지원 > 메인 리뉴얼
       //ODC 소모품
       @media (min-width: 768px) and (max-width: 1460px) {
            .odc-show-room-type1 {
                margin: 100px 16px 0 !important;
            }
        }
        @media screen and (min-width: 768px) {
            section[class*=odc] {
                margin: 100px auto 0;
            }
        }

    }
    .section {
        margin-bottom: 100px;
        padding:0 40px;
        .headline {
            max-width: 1380px;
            margin: 0 auto 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            position: relative;
            .title {
                font-size: 32px;
                font-weight: $font-weight-bold;
                line-height: 1.63;
                color: #000;
                width: 86%;
                .point {
                    color: #ea1917;
                }
            }

            .section_link {
                position: absolute;
                right: 0;
                bottom: 15px;
                display: block;
                color: #666;
                text-decoration: none;
                font-size: 14px;
                white-space: nowrap;
                &:after {
                    content: "";
                    display: inline-block;
                    margin-top: 0;
                    margin-left: 4px;
                    width: 16px;
                    height: 16px;
                    vertical-align: top;
                    background: url("/lg5-common/images/MA/mainRenewal/icon-arrow-right-g-16.svg") center no-repeat;
                    background-size: 16px 16px;
                }
            }
            
            
        }
    }


    // 상단 로그인 툴팁 박스
    .tooltip {
        &-login {
            display: block;
            max-width:100%;
            margin:0 auto;
            background-color: #DA0F47; //BTOCSITE-50181 상단 띠배너 추가] 이브닝 서비스 홍보 활동 지속 // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
            // BTOCSITE-24154 [로그인유도] 고객지원 홈 띠배너 (Hello Bar) 노출을 모바일 웹/앱으로 확대
            // @include mobile {
            //     display:none; //0625
            // }
        }
        &-inner {
            position: relative;
            max-width:1380px;
            margin:0 auto;
            padding:17px 0;
            text-align: center;
            p {
                display: inline-block;
                color:#fff;
                font-size:16px;
                line-height:26px;
                vertical-align: middle;
                strong {
                    font-weight:bold;
                }
            }

            .btn-link {
                margin-left:16px;
                color:#fff;
                font-weight:bold;
                text-decoration: none;

                &:after {
                    width:16px;
                    height:16px;
                    background-image: url("/lg5-common/images/icons/btn-arr-16-white.svg");
                    background-size:100% 100%;
                }
                
            }
            .btn-tooltip-close {
                position: absolute;
                top:50%;
                right:0;
                transform:translateY(-50%);
                width:48px;
                height:48px;
                background: url("/lg5-common/images/icons/btn-close-24-white.svg") no-repeat center center/24px 24px;
            }
            @include mobile {
                text-align: left;
                padding:10px 16px; // BTOCSITE-24154 [로그인유도] 고객지원 홈 띠배너 (Hello Bar) 노출을 모바일 웹/앱으로 확대
                p {
                    display: block;
                    font-size:13px;
                }
                .btn-link {
                    margin-left:0;
                }
            }
        }
    }
}

.cover-help {
    max-width:100%;
    margin:0 auto;
    .cover-container {
        position: relative;
        height:430px;
        margin:0 auto;
        //padding:48px 0 48px 80px;
        box-sizing:border-box;

        &:before {
            content:"";
            display: block;
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            z-index:-1;
            background: url("/lg5-common/images/CS/img-support-home-cover-pc.png") no-repeat center top;
            background-size: cover;
        }
    }

    @media screen and (max-width: 768px) {
        .cover-container {
            height: 502px;
            &:before {
                background: url("/lg5-common/images/CS/img-support-home-cover-pc.png") no-repeat center top;
                background-size: cover;
            }
        }
    }
    
    @media screen and (min-width: 768px) and (max-width: 1279px) {
        .cover-container {
            height: 502px;
            &:before {
                background: url("/lg5-common/images/CS/img-support-home-cover-pc.png") no-repeat center top;
                background-size: cover;
            }
        }
    }

    

}

// 고객지원 상단 컨텐츠 영역 내에 들어가는 제품 지원 컨텐츠
.support-nav-container {
    position: relative;
    max-width:1460px;
    //height:100%;
    height:430px;
    margin:0 auto;
    padding: 48px 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .point {
        color: #ea1917;
    }
}

@media screen and (max-width: 1023px) {
    .support-nav-container {
        flex-direction: column;
        padding: 84px 52px;
        height: 502px;
    }
}



.tablet-only-1024 {
    display: none;
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
        display: block;
    }
}

//고객지원 상단 타이틀 메시지영역
.help-txt {
    width:100%;
    max-width: 665px;
    display: flex;
    align-items: center;
    padding:0 40px;
    text-align: left;
    z-index:1;
    box-sizing:border-box;
    .tit-msg {
        display:inline-block;
        font-size:52px;
        color:#000;
        line-height:62PX;
        font-weight:bold;
        text-align: left;
        word-break: keep-all;
    }
    .btn-fast-support {
        display: none;
        margin-left:24px;
    }

    @media screen and (max-width: 1023px) {
        .tit-msg {
            font-size:42px;
            line-height:120%;
        }
    }
}

.help-cont {
    position: relative;
    display: flex;
    align-items: center;
    width:100%;
    max-width: 678px;
    height:100%;
}

@media screen and (max-width: 1023px) {
    .help-txt {
        padding: 0;
        max-width: 100%;
        margin-bottom: 30px;
    }
    .help-cont { max-width: 100%; }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .help-txt {
        width: 70%;
    }
}



.notify-box {
    width: 100%;
    height: 93px;
    border-radius: 16px;
    box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.16);
    border: solid 2px #000;
    background-color: #fff;
    z-index: 3;
    // overflow: hidden;
    .head {
        display: flex;
        align-items: end;
        justify-content: space-between;
        padding: 24px 48px 30px;
        .report-area {
            display: flex;
            align-items: center;
            min-height: 42px;
            .msg {
                font-size: 20px;
                
                b + b {
                    margin-left: 5px;
                }
            }
            .flag-wrap {
                margin-left: 8px;
                vertical-align:super;
            }
            .tit-wrap {
                .sup {
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;
                    .msg {
                        font-size: 12px;
                        color: #666;
                        display: block;
                    }
                    .flag-wrap {
                        margin-left: 0;
                        margin-right: 8px;
                    }
                }
                .tit {
                    font-size: 32px;
                }
            }
            
        }
        
    }

    &.type-item {
        height:auto;
        min-height: 240px;
        box-shadow: none;

        .head {
            border-bottom: 6px solid #fff;
            position: relative;
            z-index: 4;
            min-height: 122px;
            .tit-wrap {
                .tit {
                    font-weight: $font-weight-bold;
                }
            }
        }
    }

    .cont {
        padding: 28px 0 0;
        width: 100%;
        min-height: 116px;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        position: absolute;
        box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.16);
        border: solid 2px #000;
        border-top: none;
        overflow: hidden;
        z-index: 3;
        background-color: #fff;
        left: 0;
        &:before {
            content: "";
            display: block;
            background-color: #ddd;
            position: absolute;
            height: 1px;
            width: 90%;
            left: 5%;
            top: 0;
        }
    }
}


@media screen and (max-width: 1023px) {
    .notify-box {
        height: 83px;
        .head {
            padding: 18px 30px;
            .report-area {
                min-height: 38px;
                .msg {
                    font-size: 18px;
                }
                .tit-wrap {
                    .tit {
                        font-size: 32px;
                    }
                }
            }
        }
    
        &.type-item {
            min-height: 210px;
            .head {
                border-bottom: 6px solid #fff;
                min-height: 92px;
                padding: 20px 45px;
                .tit-wrap {
                    .tit {
                        font-size: 22px;
                    }
                }
            }
        }
    
        .cont {
            padding: 28px 0 0;
            min-height: 116px;
        }
    }
}



.btn {
    &.icon-add {
        span {
            position:relative;
            display: flex;
            align-items: center;
            padding-right:20px;
            &:before {
                content:'';
                position:absolute;
                right:0;
                top:12px;
                width: 10px;
                height: 1px;
                background:#fff;
            }
            &:after {
                content:'';
                position:absolute;
                right:0;
                top:12px;
                width: 10px;
                height: 1px;
                background:#fff;
                transform:rotate(90deg);
            }
        }
        
    }
    &.plus {
        span {
            position: relative;
            display: inline-block;
            padding-right: 20px;
            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 10px;
                width: 12px;
                height: 1px;
                background: #fff;
            }
            &::after {
                content: '';
                position: absolute;
                right: 0;
                top: 10px;
                width: 12px;
                height: 1px;
                background: #fff;
                transform: rotate(90deg);

            }
        }
    }
}

.custom-combo-wrap {
    display: flex;
    flex-wrap: wrap;
}


.combo-cont {
    width: 100%;
	overflow: hidden;
	padding: 4px 0;
	border-radius: 4px;
	background: #fff;
	box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
	z-index: 89;
    display: none;

    .curr-view {
        display: flex;
        a {
            display: block;
            overflow: hidden;
            padding: 8px 27px;
            border-radius: 4px;
            color: #000;
            font-size: 14px;
            line-height: 24px;
            white-space: nowrap;
            word-break: break-all;
            text-overflow: ellipsis;
            text-align: center;
        }
    }

    li {
        
        a {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            font-size: 16px;
            line-height: 1.5;
            color: #767676;
            padding: 12px;

            &.selected {
                background-color: #f4f4f4;
                color: #000;
            }

            .code {
                display: block;
            }
        }
    }

    li+ li {
        margin-top: 5px;
    }

}


//검색영역
.keyword-search {
    margin: -33px 0 80px;
    padding:0 40px;
    @include mobile{
        margin:36px 0 24px;
    }
    .search-box {
        max-width:850px;
        width:100%;

        .btn-search {
            right:10px;
            height:66px;
            background: center/24px 24px url(/lg5-common/images/icons/icon-search-20.svg) no-repeat;
        }
        .btn-delete {
            right:70px;
        }
    }
    .search-input {
        width:100%;
        input[type=text]  {
            padding:18px 64px 18px 32px;
            border-radius:33px;
            border: none;
            box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.14);
            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #aaa;
                opacity: 1; /* Firefox */
            }
              
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #aaa;
            }
              
            &::-ms-input-placeholder { /* Microsoft Edge */
                color: #aaa;
            }
        }
        &.clear {
            input[type=text]  {
                padding-right:90px;
            }   
        }

        
    }
    .autocomplete-box {
        .keyword-list {
            a {
                display:block;
                max-width:none;
                @include clearfix;
                .model {
                    float:left;
                }
                .category {
                    float:right;
                }  
            }
        }
    }

    .search-recommend {
        display: block;
        text-align: center;
        margin-top: 16px;
        dl {
            display: inline-flex;
            margin: 0 auto;
            align-items: center;
            dt {
                font-weight: 500;
                font-size: 14px;
                color: #666;
                position: relative;
                padding-right: 15px;
                margin-right: 15px;
                &:after {
                    position: absolute;
                    top: 55%;
                    right: 0;
                    transform: translateY(-55%);
                    content: "";
                    display: block;
                    width: 1px;
                    height: 10px;
                    background-color: #ddd;
                }
            }
            dd {
                a {
                    font-size: 14px;
                    color: #666;
                }
            }
            dd + dd {
                margin-left: 10px;
            }
        }
    }

    @include mobile {
        padding:0 16px;
        .search-box {
            .btn-search {
                right:17px;
                height:60px;
                width:34px;
            }
            .btn-delete {
                right:60px ;
            }
        }
        .search-input {
            input[type=text]  {
                padding:16px 41px 16px 20px;
            }
            &.clear {
                input[type=text]  {
                    padding-right:97px;
                }   
            }
        }
        .autocomplete-box {
            .keyword-list {
                a {
                    .category {
                        display:none;
                    }
                }
            }
        }
    }
}


//제품 카테고리 아이콘
.icon_aircon { background: url('/lg5-common/images/CS/main/cate_icon_aircon.svg') no-repeat center 0; }
.icon_styler { background: url('/lg5-common/images/CS/main/cate_icon_styler.svg') no-repeat center 0; }
.icon_refrigerator { background: url('/lg5-common/images/CS/main/cate_icon_refrigerator.svg') no-repeat center 0; }
.icon_tv {background: url('/lg5-common/images/CS/main/cate_icon_tv.svg') no-repeat center 0;}
.icon_monitor {background: url('/lg5-common/images/CS/main/cate_icon_monitor.svg') no-repeat center 0;}
.icon_cook {background: url('/lg5-common/images/CS/main/cate_icon_cook.svg') no-repeat center 0;}
.icon_cleaner {background: url('/lg5-common/images/CS/main/cate_icon_clean.svg') no-repeat center 0;}
.icon_aircare { background: url('/lg5-common/images/CS/main/cate_icon_aircare.svg') no-repeat center 0;}
.icon_mobile { background: url('/lg5-common/images/CS/main/cate_icon_mobile.svg') no-repeat center 0;}
.icon_healthcare { background: url('/lg5-common/images/CS/main/cate_icon_healthcare.svg') no-repeat center 0;}
.icon_beatyhair { background: url('/lg5-common/images/CS/main/cate_icon_beauty.svg') no-repeat center 0;}
.icon_audio { background: url('/lg5-common/images/CS/main/cate_icon_audio.svg') no-repeat center 0;}
.icon_etclife { background: url('/lg5-common/images/CS/main/cate_icon_elect.svg') no-repeat center 0;}
.icon_etc { background: url('/lg5-common/images/CS/main/cate_icon_etc.svg') no-repeat center 0;}

@include tablet{
    .icon_aircon, .icon_styler, .icon_refrigerator, .icon_tv, .icon_monitor, .icon_cook, .icon_cleaner,
    .icon_aircare, .icon_mobile, .icon_healthcare, .icon_beatyhair, .icon_audio, .icon_etclife, .icon_etc { background-size: auto 75%;}
}


//고객지원 리스트
.backup-wrap {
    padding: 0 40px;
    .inner {
        max-width: 1380px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
    }
}

.backup-item {
    width: 100%;
    padding: 40px;
    border-radius: 16px;
    background-color: #f6f6f6;

    .icon-wrap {
        .icon {
            margin-bottom: 14px;
            img {
                width: 48px;
                height: 48px;
            }
        }
        .icon-txt {
            font-size: 24px;
            font-weight: $font-weight-bold;
            margin-bottom: 24px;
        }
    }
    .list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items:center;
        gap: 5px 0;
        li {
            width: 50%;
            .text-link {
                position:relative;
                padding-right:20px;
                font-size:14px;
                line-height:24px;
                white-space:nowrap;
                &:after {
                    content: ' ';
                    position: absolute;
                    top:5px;
                    right: 0;
                    width: 12px;
                    height: 12px;
                    background: url('/lg5-common/images/icons/icon-arr-16-bk.svg') center no-repeat;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .backup-wrap {
        .inner {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }

    .backup-item {
        display: flex;
        .icon-wrap {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 0;
            width: 80%;
            margin-right: 10%;
            .icon {
                margin-bottom: 0;
            }
            .icon-txt {
                display: flex;
                align-items: center;
                margin: 0 0 0 15px;
            }
        }
    }
    
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
    .backup-item {
        .list {
            li {
                width: 100%;
            }
        }
        
    }
}





//공지사항
.home-notice {
    max-width: 1380px;
    margin: 0 auto;
    overflow:hidden;
    background-color: #f6f6f6;
    border-radius: 16px;
    position: relative;
    height:72px;
    
    .notice-inner {
        display:table;
        position: relative;
        width:100%;
        height:72px;
        margin:0 auto;
        padding:0;
        table-layout: fixed;
    }

    .notice-title {
        display:table-cell;
        width:70px;
        padding-right:10px;
        vertical-align: middle;
        .title {
            font-size:16px;
            line-height:24px; //BTOCSITE-9066 수정
            color:#000;
            font-weight:500;
        }
    }
    .notice-list-wrap {
        display:table-cell;
        position: relative;
        width:100%;
        //padding:0 84px 0 16px;
        padding:0 186px 0 16px;
        font-size:16px;
        line-height: 24px;
        box-sizing:border-box;
        vertical-align: middle;
        opacity:0;
        transition:opacity .3s;
        color:#000; //BTOCSITE-9066 추가
        &.slick-initialized {
            opacity:1;
        }
        // &:before {
        //     content:"";
        //     display:block;
        //     position: absolute;
        //     top:50%;
        //     left:0;
        //     transform:translateY(-50%);
        //     width:1px;
        //     height:12px;
        //     background: #ddd;
        // }
        .flag-wrap {
            margin-left:6px;
            .flag {
                font-size:12px;
                line-height:1;
                height:auto;
            }
        }
        .notice-list {
            height:71px;
            line-height:71px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            a{
                @include textEllipsis;
            }
            .flag-wrap {
                margin-right: 8px;
            }
        }
        .slide-contents {
            overflow:hidden;
            height:70px;
        }
    }
    .slick-arrow {
        position: absolute;
        width:36px;
        height:36px;
        padding:6px;
        top:50%;
        transform:translateY(-50%);
        background-size: 36px 36px;
        background-position: center center;
        background-repeat:no-repeat;
        display: none !important;

        &:before {
            display: none;
        }

        &.slick-prev {
            left:auto;
            right:56px;
            background-image: url('/lg5-common/images/CS/icon-notice-up-24.svg');
        }
        &.slick-next {
            right:28px;
            background-image: url('/lg5-common/images/CS/icon-notice-down-24.svg');
        }
    }

    .slick-control {
        position: absolute;
        top: 12px;
        right: 100px;
        width: 109px;
        height: 46px;
        margin: 0 8px 0 0;
        padding: 11px 32px;
        background-color: rgba(0, 0, 0, 0.35);
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 16px;
        .dash {
            margin: 0 5px;
        }
        .total {
            color: rgba(255, 255, 255, 0.7);
        }

    }

    .btn-play {
        display: block;
        position: absolute;
        width:46px;
        height:46px;
        top:50%;
        right:48px;
        transform:translateY(-50%);
        background-size: 46px;
        background-position: center center;
        background-repeat:no-repeat;
        background-image: url('/lg5-common/images/CS/pause_x46.svg');
        opacity: 0.6;

        &.pause {
            background-image: url('/lg5-common/images/icons/btn-play-16-gray.svg');
        }
    }
    // @include tablet {
    //     .notice-inner { 
    //        max-width: 100%;
    //        padding:0 10px;
    //     }
    //     .slick-arrow {
            
    //     }

    //     .btn-play {
    //         right: 10px;
    //     }
    // }
    
}


// 문제해결Q&A
.guide-list {
    .inner {
        max-width: 1380px;
        margin: 0 auto;
        ul {
            li {
                width: 100%;
                border-bottom: 1px solid #ddd;
                a {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: 25px 24px;
                    color: #666;
                    font-size: 16px;
                    line-height: 1.38;
                    .category {
                        width: 16%;
                        min-width: 210px;
                        color: #000;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        margin-right: 24px;
                        .icon {
                            display: inline-block;
                            width: 40px;
                            height: 40px;
                            background-size: auto 40px;
                            margin-right: 8px;
                            background-position: center;
                            img {
                                width:100%;
                            }
                        }
                        @include tablet{
                            .icon_aircon, .icon_styler, .icon_refrigerator, .icon_tv, .icon_monitor, .icon_cook, .icon_cleaner,
                            .icon_aircare, .icon_mobile, .icon_healthcare, .icon_beatyhair, .icon_audio, .icon_etclife, .icon_etc { 
                                background-size: auto 40px;
                                background-position: center;
                            }
                        }
                        .txt {
                            @include textEllipsis;
                        }
                    }
                    .title {
                        display: flex;
                        align-items: center;
                        width: 65%;
                        .txt {
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            word-wrap: normal;
                            overflow: hidden;
                            max-width: 80%;
                            -webkit-line-clamp: 1;
                        }
                        .icon-movie {
                            display: block;
                            width:25px;
                            height:25px;
                            background:url(/lg5-common/images/icons/btn-play-32-black.svg) no-repeat;
                            background-size:25px;
                            margin-left: 8px;
                        }
                    }

                    .info {
                        display: block;
                        width: 20%;
                        //justify-content: flex-end;
                        .count {
                            display: block;
                            float: left;
                            width: 100%;
                            text-align: right;
                            // .txt {
                            //     text-align: left;
                            //     margin-right: 5px;
                            // }
                        }
                        //.date {
                        //   display: block;
                        //    float: left;
                        //    width: 50%;
                        //    text-align: center;
                        //}
                    }
                    // .date {
                    //     width: 10%;
                    // }
                    // .count {
                    //     width: 9%;
                    //     .txt {
                    //         margin-right: 5px;
                    //     }
                    // }
                }
            }
        }
    }
}


@include tablet {
    .guide-list {
        .inner {
            ul {
                li {
                    a {
                        flex-direction: column;
                        align-items: flex-start;
                        padding: 15px 24px;
                        font-size: 13px;
                        gap: 8px 0;
                        .category {width: 100%;}
                        .title {
                            width: 100%;
                            .icon-movie {
                                width: 20px;
                                height: 20px;
                                background-size:20px;
                            }
                        }
                        .info {
                            width: 100%;
                            //justify-content: flex-start;
                            .date {
                                width: auto;
                                //align-items: center;
                            }
                            .count {
                                width: auto;
                                &:before {
                                    content: "";
                                    display: inline-block;
                                    position: relative;
                                    top: 1px;
                                    width: 1px;
                                    height: 10px;
                                    background-color:#ddd;
                                    margin: 0 8px;
                                }
                                //.txt {margin-right: 5px;}
                            }
                        }
                        
                    }
                }
            }
        }
    }
}





////////
.sort-select-wrap.csHome {
    width: 100%;
    color: #000;
    select {
        margin-left: 48px;
    }
    .ui-selectbox-wrap {
        .ui-selectbox-view {
            padding:0 48px;
        }
        .ui-select-button {
            padding-bottom: 23px;
            .ui-select-text {
                font-size: 24px;
            }
            &.placeholder {
                .ui-select-text {
                    color: #000;
                }
            }
            &.single {
                pointer-events: none;
            }
            .ico {
                top:36%;
            }
            .info {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 14px;
                font-size: 14px;
                color: #666;
                .flag-wrap {
                    margin-right: 8px;
                    .flag {
                        color: #666;
                        font-size: 12px;
                    }
                }
            }
        }

        .ui-selectbox-list {
            width: 100%;
            position: initial;
            left: initial;
            transform: initial;
            box-shadow: none;
            padding:0;
            margin:-10px 0 20px;
            ul {
                li {
                    position:relative;
                    padding:0 48px;
                    a {
                        text-align: left;
                        padding:12px 0;
                        border-top:1px solid #f3f3f3;
                        border-radius:0;
                        font-size: 16px;
                        color: #666;
                        .ui-select-text {
                            margin-bottom:8px;
                        }
                        .info {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            font-size: 14px;
                            color: #666;
                            .flag-wrap {
                                margin-right: 8px;
                                .flag {
                                    color: #666;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                    &:hover{
                        background-color: #f4f4f4;
                        font-weight: $font-weight-regular;
                    }
                    &.on {
                        background-color: #f4f4f4;
                        font-weight: $font-weight-bold;
                        a {
                            border:none;
                            color: #000;
                            .info { color:#000; }
                        }
                        + li {
                            a {
                                border:none;
                            }
                        }
                    }
                }
            }
            .ui-select-scrollarea {
                max-height:400px;
            }
        }
    }
}

@include tablet{
    .sort-select-wrap.csHome select {
        width: calc(100% - 230px) !important;
    }
}


///////////////////////
/// 공지사항
.section.info-section {
    .info-area-wrap {
        margin: 0 auto;
        padding: 18px 40px 16px;
        max-width: 1380px;
        height: 88px;
        border-radius: 16px;
        background-color: #f6f6f6;
        display: flex;
        align-items: center;
        position: relative;
        .slide-page {
            display: flex;
            align-items: center;
            width: 100px;
            height: 46px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        @include tablet {
            .slide-page {
                height: 46px;
            }
        }
        
        .info-area {
            width:70%;
            height: 46px;
            overflow: hidden;
            .slide-item {
                display: flex;
                align-items: center;
                height: 46px;
                a{
                    height: 46px;
                    font-size: 14px;
                    overflow: hidden;
                    display: block;
                    width: 100%;
                }
            }
        }
        
    }
	
}



// @media screen and (min-width: 768px) and (max-width: 1279px) {
// 	.section.info-section .info-area-wrap {
//         height: 108px;
//         align-items: flex-start;
//         justify-content: flex-end;
// 	}
// }

@media screen and (max-width: 768px) {
	.section.info-section .info-area-wrap {
		padding: 19px 24px 13px;
        flex-direction: column;
        align-items: flex-start;
	}
}

.section.info-section .info-area-wrap .tit {
	font-size: 16px;
	line-height: 32px;
	color: #000;
    font-weight: $font-weight-bold;
    margin-right: 30px;
}

@media screen and (max-width: 768px) {
	.section.info-section .info-area-wrap .tit {
		line-height: 20px;
	}
}


@media screen and (min-width: 768px) and (max-width: 1279px) {
	.section.info-section .info-area-wrap .info-area .slick-list {
		width: calc(100% - 20px);
	}
}


.section.info-section .info-area-wrap .item-box {
	display: flex;
    align-items: center;
    height: 46px;
    .flag-wrap {
        margin-right: 8px;
    }
}

.section.info-section .info-area-wrap .item-box p {
	font-size: 16px;
	line-height: 20px;
	color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    overflow: hidden;
    max-width:94%;
	-webkit-line-clamp: 1;
}


.section.info-section .slide-page {
	position: absolute;
	display: flex;
	align-items: center;
    justify-content: center;
	color: #fff;
	background: rgba(0, 0, 0, 0.35);
    border-radius: 40px;
    right: 94px;
}

@media screen and (min-width: 768px) {
	.section.info-section .slide-page {
		top: 65px;
		height: 46px;
		padding: 0 30px;
		font-size: 16px;
		
	}
}


.section.info-section .slide-page .count {
	color: rgba(255, 255, 255, 0.7);
}

.section.info-section .slide-page .count:before {
	content: '/';
	margin: 0 8px;
	color: #fff;
}

@media screen and (max-width: 767px) {
	.section.info-section .slide-page .count:before {
		margin: 0 5px;
	}
}

.section.info-section .btn-info-play {
    position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 40px;
	width: 46px;
	height: 46px;
	background-size: 100% 100%;
	z-index: 1;
	background-image: url(/lg5-common/images/MA/mainRenewal/icon-notice-play.svg);
}

.section.info-section .btn-info-play.pause {
	background-image: url(/lg5-common/images/MA/mainRenewal/icon-notice-pause.svg);
}


.section.info-section .slick-arrow {
	width: 32px;
	height: 32px;
	background-size: 32px 32px;
}

.section.info-section .slick-arrow.slick-prev {
	right: 128px;
	background-image: url(/lg5-common/images/MA/mainRenewal/icon-notice-up.svg);
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
	.section.info-section .slick-arrow.slick-prev {
		right: 120px;
	}
}

@media screen and (max-width: 767px) {
	.section.info-section .slick-arrow.slick-prev {
		top: 16px;
		right: 72px;
	}
}

.section.info-section .slick-arrow.slick-next {
	right: 84px;
	background-image: url(/lg5-common/images/MA/mainRenewal/icon-notice-down.svg);
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
	.section.info-section .slick-arrow.slick-next {
		right: 76px;
	}
}

@media screen and (max-width: 767px) {
	.section.info-section .slick-arrow.slick-next {
		top: 16px;
		right: 48px;
	}
}

@media screen and (max-width: 767px) {
	.section.info-section .slick-arrow {
		width: 16px;
		height: 16px;
		background-size: 16px 16px;
	}
}

@media screen and (max-width: 767px) {
	.section .slide-wrap {
		padding-bottom: 0;
	}
}

@media screen and (max-width: 767px) {
	.section .slide-wrap .slide-content {
		margin: 0;
	}
}

.section .item__image img {
	width: 100%;
	height: 100%;
}

.section .item__name {
	display: block;
	font-weight: 700;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

@media screen and (min-width: 768px) {
	.section .item__name {
		font-size: 20px;
		line-height: 28px;
	}
}

@media screen and (max-width: 767px) {
	.section .item__name {
		font-size: 14px;
		line-height: 20px;
	}
}

.section .item__sku {
	display: block;
	line-height: 1.43;
	color: #666;
}

@media screen and (min-width: 768px) {
	.section .item__sku {
		margin-top: 2px;
		font-size: 16px;
	}
}

@media screen and (max-width: 767px) {
	.section .item__sku {
		margin-top: 1px;
		font-size: 11px;
	}
}

// 상품아이콘 메뉴 슬라이드
.slide-wrap.carousel-type.carousel-micon {
	max-width: 1380px;
    overflow: hidden;
}

@include tablet {
    .slide-wrap.carousel-type.carousel-micon {
        overflow: inherit;
    }
}

.slide-wrap.carousel-type.carousel-micon .indi-wrap {
	bottom: 0;
}

.slide-wrap.carousel-type.carousel-micon .slide-controls {
    display: block;
}

.slide-wrap.carousel-type.carousel-micon .slide-controls .btn-arrow.prev {
	left: -50px;
}

@media screen and (max-width: 768px) {
	.slide-wrap.carousel-type.carousel-micon .slide-controls .btn-arrow.prev {
		left: -15px;
	}
}

.slide-wrap.carousel-type.carousel-micon .slide-controls .btn-arrow.next {
	right: -50px;
}

@media screen and (max-width: 768px) {
	.slide-wrap.carousel-type.carousel-micon .slide-controls .btn-arrow.next {
		right: -15px;
	}
}

@media screen and (max-width: 768px) {
	.slide-wrap.carousel-type.carousel-micon .slide-content {
        max-height: 120px;
        overflow: hidden !important;
	}
}

.slide-wrap.carousel-type.carousel-micon .slide-conts {
    width: calc(100% / 7);
    height: 120px;
    &:nth-child(8) {
        clear: left;
    }
    &:nth-child(n+8) {
        margin-top: 48px;
    }
}


@media screen and (max-width: 780px) {
	.slide-wrap.carousel-type.carousel-micon .slide-conts {
		//width: 18vw;
		padding: 6px 6px 12px;
        &:nth-child(8) {
           clear: inherit;
        }
        &:nth-child(n+8) {
            margin-top: 0;
        }
	}
}

.slide-wrap.carousel-type.carousel-micon .slide-box {
	position: relative;
	padding: 0;
    height: 100%;
}



.slide-wrap.carousel-type.carousel-micon .slide-box a {
    display: flex;
    flex-direction: column;
    height: 100%;
    span {
        text-align: center;
    }
    .icon {
        width: 100%;
        height: 80px;
    }
    .text {
        height: 40px;
        display: flex;
        align-items: end;
        justify-content: center;
    }
}




.slide-wrap.carousel-type.carousel-micon .slide-box .flag-wrap {
	height: 20px;
	margin-bottom: 8px;
}

.slide-wrap.carousel-type.carousel-micon .slide-box .flag-wrap + .product-name {
	margin-top: 0;
}

.slide-wrap.carousel-type.carousel-micon .slide-box .product-name {
	height: 52px;
	margin: 8px 0;
}

@media screen and (max-width: 767px) {
	.slide-wrap.carousel-type.carousel-micon .slide-box .product-name {
		margin: 8px 0 16px;
	}
}

.slide-wrap.carousel-type.carousel-micon .slide-box .product-name a {
	font-weight: 700;
	font-size: 16px;
	line-height: 26px;
	overflow: hidden;
	text-overflow: clip;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

@media screen and (max-width: 767px) {
	.slide-wrap.carousel-type.carousel-micon .slide-box .product-name a {
		font-size: 14px;
		line-height: 22px;
	}
}

.slide-wrap.carousel-type.carousel-micon .slide-box .price-area .total {
	display: block;
	font-weight: 700;
	font-size: 18px;
	line-height: 28px;
}

@media screen and (max-width: 767px) {
	.slide-wrap.carousel-type.carousel-micon .slide-box .price-area .total {
		font-size: 15px;
		line-height: 23px;
	}
}

.slide-wrap.carousel-type.carousel-micon .slide-box .price-area .discount {
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	color: #666;
}

@media screen and (max-width: 767px) {
	.slide-wrap.carousel-type.carousel-micon .slide-box .price-area .discount {
		font-size: 13px;
		line-height: 21px;
	}
}

.slide-wrap.carousel-type.carousel-micon .slide-box .price-area .discount-rate {
	font-size: 16px;
	font-weight: bold;
	line-height: 1.5;
	color: #ea1917;
}

.slide-wrap.carousel-type.carousel-micon .slide-box .btn {
	width: 100%;
}

@media screen and (max-width: 767px) {
	.slide-wrap.carousel-type.carousel-micon .slide-box .btn {
		padding: 11px 0;
		font-size: 14px;
		line-height: 18px;
	}
}

.slide-wrap.carousel-type.carousel-micon .slide-box .chk-wish-wrap {
	position: absolute;
	top: 24px;
	right: 24px;
}

@media screen and (max-width: 767px) {
	.slide-wrap.carousel-type.carousel-micon .slide-box .chk-wish-wrap {
		top: 18px;
		right: 18px;
	}
}


// BTOCSITE-51308 고객지원 홈 ] (모바일 버전) 하단 배너 노출 빈도 개선
//관련된 정보
.related-pages {
    .item {
        // float:left;
        position: relative;
        // width:33.33%;
        // padding:0 12px;
        &:not(:last-of-type){
            margin: 0 0 40px 0;
        }
        &.hidden {
            display:none;

            &.show {
                display: block;
            }
        }

        &-list {
            position:relative;
            // margin-left:-12px;
            // margin-right:-12px;

            @include clearfix;
        }

        &-cont {
            position: absolute;
            top:0;
            left:0;
            display:flex;
            align-items: center;
            width:100%;
            height:100%;
            padding:35px 96px;
            box-sizing:border-box;

            font-size:24px;
            font-weight:bold;
            color:#000;
            line-height:32px;
            .font-white{
                color: white;
            }
            .font-small {
                font-size:13px;
            }
        }

        > a {
            display:block;
            position: relative;
            overflow:hidden;
            border-radius:8px;
            //width:444px;
            // height: 180px;

            img {
                // position: absolute;
                // top:50%;
                // left:50%;
                // transform: translate(-50%,-50%);
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    // BTOCSITE-14560 고객지원 하단 배너 2단 노출 시 padding 처리
    // @include pc {
    //     .item {
    //         //&:nth-child(n+4) {
    //            //margin-top: 12px;
    //         //}
    //     }
    // }
    @include pc-size-m {
        .item {
            &:not(:last-of-type){
                margin: 0 0 20px 0;
            }
            &-cont {     
                padding:30px 96px;
                font-size:20px;
            }
        }
    }
    @include tablet {
        .item {
            &-cont {
                padding:0 20px;
                font-size:14px;
                line-height: 1.4;
                .font-white{
                    color: white;
                }
                .font-small {
                    font-size:10px;
                }
            }
        }
    }
    @include mobile {
        .item {
            &-cont {
                padding:0 14px;
            }
        }
    }
    .btn-moreview {
        display:none !important;
    }
    
}

.common-banner {
    margin-top:100px;
    padding-top:0;
    border-top:none;
    .banner {
        .banner-tit {
            margin-bottom:32px;
            text-align:left;
            @include mobile{                
                margin-bottom:19px;
            }
        }
    }
    // &.main-service, &.awards {
    //     display:none !important;
    // }
}

// .common-banner .banner {   
//     max-width: 1380px;
//     padding: 0;
// }


.slide-wrap.carousel-type {
    &.carousel-1 {
        &.new-type {
            max-width: 1380px;
            width: 100%;
            margin: 0 auto;
            padding-bottom: 40px;
            .indi-wrap {
                position: absolute;
                bottom: 0;
                margin-top: 0;
                li {
                    padding: 0 4px;
                    .btn-indi {
                        width: 8px;
                        height: 8px;
                        &:before {
                            width: 8px;
                            height: 8px;
                            border-radius: 6px;
                            background-color: #000;
                            opacity: 0.2;
                        }
                    }
                    &.on {
                        .btn-indi{
                            width: 24px;
                            &:before {
                                width: 24px;
                                border-radius: 6px;
                                background: #000;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            .slide-controls {
                // display: none;
                right: 0;
                left: 0;
                top: 35%;
                transform: translateX(0px);
                .btn-arrow {
                    &.prev,
                    &.next {
                        transform: translateX(0);
                    }
                    &.prev {
                        left: -18px;
                    }
                    &.next {
                        right: -18px;
                    }
                }
                @media screen and (max-width: 1380px) {
                    .btn-arrow {
                        &.prev {
                            left: 20px;
                        }
                        &.next {
                            right: 20px;
                        }
                    }
                }
            }
            .slide-content {
                .slide-conts {
                    height: 210px;
                    .slide-box {
                        display: block;
                        height: 100%;
                        width: 100%;
                        border-radius: 8px;
                        overflow: hidden;
                        position: relative;
                        .thumb {
                            display: block;
                            height: 100%;
                            width: 100%;
                            border-radius: 8px;
                            img {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 8px;
                            }
                        }
                        .item-cont {
                            position: absolute;
                            left: 30px;
                            top: 10px;
                            font-size: 24px;
                            line-height: 32px;
                        }
                    }

                }
            }
        }
    }
}




// BTOCSITE-70096 고객지원 > 메인 리뉴얼
// 히어로 영역
.main-service-wrap {
    .common-banner.main-service {
        margin-top: 0;
        .banner {
            position: relative;
            min-width: 768px;
            max-width: 1920px;
            padding: 0;
            overflow: hidden;
            @include pc {
                height: auto;
            }
            @include tablet {
                height: auto;
            }            
           
        }
        .bann_head {
            display: none;
        }
        .bann_content {
            .main-service-slider {
                & > .slide-item {
                    &:not(:first-of-type) {
                        display: none;
                    }
                }
            }
            .slick-track {
                display: flex;
                flex-direction: row;

            }
            .slide-item {

                .slide-item-wrap {
                    position: relative;
                    overflow: hidden;
                    @include pc {
                        height: auto;
                    }
                    @include tablet {
                        height: auto;
                    }

                    .slide-thumb-area {
                        // position: absolute;
                        // transform: translateX(-50%);
                        // left: 50%;
                        // top: 0;
                        img {
                            width: 100%;
                            &.mo-only {
                                display: none;
                            }
                        }
                    }
                    .slide-cont-area {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 22%;
                        .slide-title {
                            font-weight: 700;
                            font-size: 40px;
                            line-height: 52px;
                            span {
                                display: block;
                            }
                            .arr-right {
                                &::after {
                                    content: "";
                                    display: inline-block;
                                    position: relative;
                                    width: 16px;
                                    height: 16px;
                                    background: url(/lg5-common/images/CS/ico_right_16.svg) no-repeat center center / 100% auto;
                                }
                            }
                            .font-white {
                                color: white;
                            }
                            .font-small {
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 22px;
                                margin-top: 16px;
                            }


                            @include tablet {
                                font-size: 28px;
                                line-height: 2rem;
                            }
                        }
                    }
                }
            }
        }
        

        .slide-count {
            z-index: 1;
            position: absolute;
            bottom: 14px;
            right: 270px;
            display:inline-block;
            border-radius:32px;
            height:32px;
            padding:0 16px 0 7px;
            background:rgba(0,0,0,0.35);
            color:#fff;
            font-size: 14px;
            line-height:30px;
            strong {
                font-weight: 400;
            }
            span {
                opacity: 0.7;
            }
        }
        .btn-play {
            z-index: 1;
            position: relative;
            top: initial;
            right: initial;
            display: inline-block;
            transform: initial;
            width:27px;
            height:27px;
            opacity: 1;
            background: no-repeat center center / 15px auto url('/lg5-common/images/CS/icon/icon_video_pause.svg');
            &.pause {
                background: no-repeat center center / 15px auto url('/lg5-common/images/CS/icon/icon_video_play.svg');
            }
        }
        
        .slick-arrow {
            
            &::before {
                box-shadow: none;
            }
            &::after {
                border-width: 0 2px 2px 0;
            }
            &.slick-prev {
                left: 270px;
                &::after {
                    left: 21px;
                }
            }
            &.slick-next {
                right: 270px;
                &::after {
                    right: 21px;
                }
            }
        }

        @include pc-size-l {
            .slide-count {
                right: 1.8vw;
            }
            .slick-arrow {
                &.slick-prev {
                    left: 1.8vw;
                }
                &.slick-next {
                    right: 1.8vw;
                }
            }
        }
    }

}

// 공지사항
.home-notice-wrap {
    &.home-notice {
        max-width: 1920px;
        height: 80px;
        border-radius: 0;

        @media screen and (max-width: 1412px){
            .notice-inner {
                padding: 0 16px;
            }
            @media screen and (max-width: 1404px){
                .slide-count {
                    right: 24px !important;
                }
            }
        }
        @include tablet {
            .notice-inner {
                padding: 0 16px;
            }
            .slide-count {
                right: 16px !important;
            }
        }
        .notice-inner {
            min-width: 768px;
            max-width: 1380px;
            height: 80px;
            margin: 0 auto;
            .notice-list-wrap {
                padding: 0;
            }
        }
        .notice-list-wrap .notice-list {
            height: 80px;
            line-height: 80px;
            .flag-wrap {
                margin-left: 0;
            }
            
        }
        
        .slide-count {
            z-index: 1;
            position: absolute;
            top: calc(50% - 16px);
            right: 0;
            display:inline-block;
            border-radius:32px;
            height:32px;
            padding:0 10px 0 7px;
            background:rgba(0,0,0,0.35);
            color:#fff;
            font-size: 14px;
            line-height:30px;
            strong {
                font-weight: 400;
            }
            span {
                opacity: 0.7;
            }
        }
        .btn-play {
            z-index: 1;
            position: relative;
            top: initial;
            right: initial;
            display: inline-block;
            transform: initial;
            width:27px;
            height:27px;
            opacity: 1;
            background: no-repeat center center / 15px auto url('/lg5-common/images/CS/icon/icon_video_pause.svg');
            &.pause {
                background: no-repeat center center / 15px auto url('/lg5-common/images/CS/icon/icon_video_play.svg');
            }
        }
        .btn-prev {
            position: relative;
            top: -1px;
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 3px;
            &::before {
                content: '';
                display: block;
                width: 16px;
                height: 16px;
                background-size: 16px;
                background-repeat: no-repeat;
                background-image: url(/lg5-common/images/icons/btn-arr-20x20-lt.svg);
            }
        }
        .btn-next {
            position: relative;
            top: -1px;
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-left: 3px;
            &::before {
                content: '';
                display: block;
                width: 16px;
                height: 16px;
                background-size: 16px;
                background-repeat: no-repeat;
                background-image: url(/lg5-common/images/icons/btn-arr-20x20-rt.svg);
            }
        }
        
    }
}

// 링크 영역
.home-links-wrap {
    max-width: 1380px;
    margin: 0  auto;
    @media screen and (max-width: 1412px){
        padding: 0 16px;
    }
    @include tablet {
        padding: 0 16px;
    }

	ul {
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: space-between;
		li {
            float: left;
            min-width: 124px;
			a {
				position: relative;
				display: block;
				font-size: 16px;
				line-height: 24px;
                font-weight: 400;
                text-align: center;
				&:before {
					content: '';
					display: block;
					width: 88px;
					height: 88px;
					border-radius: 50%;
					margin: 0 auto 12px;
					background-color: #f9f9f9;
					background-repeat: no-repeat;
					background-size: 50px;
					background-position: 50% 50%;
				}
                &.icon-reserv {
                    &:before {
                        background-image: url('/lg5-common/images/CS/icon/icon_reservation_v2.svg');
                    }
                }
                &.icon-result {
                    &:before {
                        background-image: url('/lg5-common/images/CS/icon/icon_reserv_result_v2.svg');
                    }
                }
                &.icon-contact {
                    &:before {
                        background-image: url('/lg5-common/images/CS/icon/icon_contact_us_v2.svg');
                    }
                }
                &.icon-manual {
                    &:before {
                        background-image: url('/lg5-common/images/CS/icon/icon_manual_v2.svg');
                    }
                }
                &.icon-visit {
                    &:before {
                        background-image: url('/lg5-common/images/CS/icon/icon_visit_v2.svg');
                    }
                }
                &.icon-goods {
                    &:before {
                        background-image: url('/lg5-common/images/CS/icon/icon_goods_v2.svg');
                    }
                }
                &.icon-wash {
                    &:before {
                        background-image: url('/lg5-common/images/CS/icon/icon_wash_v2.svg');
                    }
                }
                &.icon-removal {
                    &:before {
                        background-image: url('/lg5-common/images/CS/icon/icon_removal_v2.svg');
                    }
                }
			}
			
			
		}
	}
}

// 로그인 영역
.login-info-wrap {
    position: relative;
    max-width: 1380px;
    margin: 60px auto 40px auto;
    @media screen and (max-width: 1412px){
        padding: 0 16px;
    }
    @include tablet {
        padding: 0 16px;
    }
    .text-area {
        &:has(.btn){
            .text {
                margin-bottom: 20px;
            }
        }
        .text {
            color: #0F0F0F;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            strong {
                display: block;
                em {
                    color: $color-primary;
                }
            }
            span {
                display: block;
            }
        }
        .btn {
            font-size: 15px;
            line-height: 22px;
            &.mix {
                padding: 12px 24px;
            }

        }

        @include tablet {
            width: 50%;
        }
    }

    .center-area {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        @media screen and (max-width: 1412px){
            padding: 0 16px;
        }
        @include tablet {
            right: 16px;
        }
        .sort-select-wrap {
            position: relative;
            padding-left: 20px;
            select {
                position: relative;
                top: 0;
                font-size: 16px;
                line-height: 24px;
            }
            &::before {
                content: '';
                display: inline-block;
                width: 16px;
                height: 16px;
                position: absolute;
                left: 0;
                top: 4px;
                background: no-repeat center center / 100% auto url('/lg5-common/images/CS/icon/icon_place.svg');
            }
            .ui-selectbox-wrap {
                .ui-selectbox-list {
                    border: 1px solid #aaa;
                    ul {
                        li {
                            a {
                                color: #666;
                                text-align: left;
                            }
                            &.on a {
                                background: #f6f6f6;
                            }
                        }
                    }
                    ul li.on a {
                        background: #f6f6f6;
                    }
                }
                .ui-select-button {
                    padding-right: 32px;
                    .ui-select-text {
                        font-size: 16px;
                    }
                    .ico {
                        width: 20px;
                        height: 20px;
                        margin-top: -10px;
                        transform: rotate(90deg);
                        border: 1px solid #ddd;
                        border-radius: 20px;
                        background: no-repeat center center / 100% auto #fff url('/lg5-common/images/icons/btn-arr-20x20-black.svg');
                        
                    }
                }
            }
        }
        .btn-center-detail {
            color: #0f0f0f;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
    
            &::after {
                content: '';
                position: relative;
                top: -2px;
                display: inline-block;
                width: 14px;
                height: 14px;
                margin-left: 4px;
                vertical-align: middle;
                background: no-repeat center / 100% auto url('/lg5-common/images/CS/ico_right_16.svg');
            }
        } 
        .sep {
            display: inline-block;
            background-color: #eee;
            width: 1px;
            height: 14px;
            font-size: 0;
            margin: 0 36px;
        }
    }
}

// 최근 이용 서비스
.recently-reserv-wrap {
    min-width: 768px;
    max-width: 1380px;
    margin: 0  auto 40px auto;
    @media screen and (max-width: 1412px){
        padding: 0 16px;
    }
    @include tablet {
        padding: 0 16px;
    }
   
    .reserv-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 16px;
    }
    .reserv-list {
        .reserv-box {
            flex-shrink: 0;
            width: calc(50% - 12px);
            border: 1px solid #0F0F0F ;
            border-radius: 16px;
            padding: 24px;
            margin-right: 24px;
            margin-bottom: 1px; // border 사라짐 bug

            .reserv-area {
                a {
                    .reserv-number {

                        &::after{
                            content: "";
                            display: inline-block;
                            position: relative;
                            top: 2px;
                            width: 14px;
                            height: 14px;
                            background: url(/lg5-common/images/icons/btn-arr-16-down.svg) no-repeat center center;
                            background-size: 14px auto;
                            transform: rotate(-90deg);
                            opacity: 0.9;
                            margin-left: 4px;
                        }
                    }
                }
                p {
                 &:not(:last-of-type) {
                    margin-bottom: 8px;
                 }
                }
                .reserv-number {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #666;
                    .flag {
                        display: inline-block;
                        background-color: rgba(228, 228, 228, 0.7);
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        color: #000;
                        margin-right: 8px;
                        border-radius: 4px;
                        padding: 2px 6px 2px 6px;
                        height: 20px;
                    }
                }
                .reserv-topic {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #0F0F0F;
                    word-wrap: break-word;
                    strong {
                        font-weight: 700;
                    }

                }
            }
            .reserv-date {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #666;
                span {
                    &:not(:first-of-type) {
                        &::before {
                            content: '';
                            display: inline-block;
                            width: 1px;
                            height: 10px;
                            background-color: #ddd;
                            position: relative;
                            left: 0;
                            top: 0;
                            margin: 0 6px;
                        }
                    }
                }
            }
            .btn-area {
                border-top: 1px solid #eee;
                margin-top: 16px;
                padding-top: 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .btns {
                    a, button {
                        position: relative;
                        display: inline-block;
                        flex: 1;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 24px;
                        color: #0F0F0F;
                        text-align: center;
                        vertical-align: middle;
                        &:not(:first-child) {
                            margin-left: 16px;
                        }
                        span {
                            display: inline-block;
                            position: relative;
                            padding-right: 20px;
                            &::after {
                                content: '';
                                position: absolute;
                                top: 3px;
                                right: 0;
                                display: inline-block;
                                width: 16px;
                                height: 16px;
                                background: url(/lg5-common/images/CS/ico_right_16.svg) no-repeat center center / 100% auto;
                            }
                        }
                    }
                }

                
                @include tablet {
                    flex-direction: column;
                    align-items: flex-start;
                    border-top: none;
                    margin-top: 8px;
                    padding-top: 0;
                    .btns {
                        display: flex;
                        width: 100%;
                        border-top: 1px solid #eee;
                        margin-top: 16px;
                        padding-top: 16px;
                    }
                }
            }
        }
        
    }
    .swiper-container {
        width: 100%;
        overflow: hidden;
        .swiper-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
        }
    }
    .swiper-controller {
        position: relative;
        .swiper-button-prev {
            position: absolute;
            top: 50%;
            left: -24px;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            &::before {
                content: ' ';
                display: block;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: white;
                background-repeat: no-repeat;
                background-size: 100%;
                box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.10);
                border: 1px solid #ddd;
            }
            &::after {
                position: absolute;
                top: 20px;
                left: 21px;
                content: '';
                display: inline-block;
                border: solid black;
                border-width: 0 1px 1px 0;
                padding: 4px;
                transform: rotate(135deg);
                -webkit-transform: rotate(135deg);
            }
            &.swiper-button-disabled {
                display: none;
            }
        }
        .swiper-button-next {
            position: absolute;
            top: 50%;
            right: -24px;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            &::before {
                content: ' ';
                display: block;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: white;
                background-repeat: no-repeat;
                background-size: 100%;
                box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.10);
                border: 1px solid #ddd;
            }
            &::after {
                position: absolute;
                top: 20px;
                right: 21px;
                content: '';
                display: inline-block;
                border: solid black;
                border-width: 0 1px 1px 0;
                padding: 4px;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
            }
            &.swiper-button-disabled {
                display: none;
            }
        }
    }
}

// 가전 꿀팁 배너
.related-pages-wrap {
    max-width: 1380px;
    margin: 0 auto;
    @media screen and (max-width: 1412px){
        padding: 0 16px;
    }
    @include tablet {
        padding: 0 16px;
    }
    .related-pages {
        &.common-banner {
            padding: 0;
            .banner {
                padding: 0 0 26px 0;
            }
            .banner-tit {
                display: block;
                font-size: 28px;
                line-height: 40px;
                font-weight: 700;
                margin-bottom: 28px;
            }
            .page-list-wrap {
                position: relative;
            }
        }
        .item {
            position: relative;
            &:not(:first-of-type){
                display: none;
            }
            &:not(:last-of-type){
                margin: 0;
            }
            &.hidden {
                display:none;

                &.show {
                    display: block;
                }
            }

            &-list {
                position:relative;
                @include clearfix;
            }

            &-cont {
                position: absolute;
                top:0;
                left:0;
                display:flex;
                align-items: center;
                width:100%;
                height:100%;
                padding:35px 96px;
                box-sizing:border-box;

                font-size:24px;
                font-weight:bold;
                color:#000;
                line-height:32px;
                .font-white{
                    color: white;
                }
                .font-small {
                    font-size:16px;
                }

                @include tablet {
                    font-size: 20px;
                    line-height: 1.3rem;
                }
            }

            > a {
                display:block;
                position: relative;
                overflow:hidden;
                border-radius:8px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        .slick-initialized {
            .item {
                &:not(:first-of-type){
                    display: block;
                }
            }
        }
        .slick-arrow {
            &:before {
                box-shadow:none;
                opacity: 0.8;
            }
        }
        .slick-dots {
            bottom: -26px;
        }
        .slick-slide {
            @include pc {
                img {
                    &.mo-only {
                        display: none !important;
                    }
                }
            }
            @include mobile {
                img {
                    &.pc-only {
                        display: none !important;
                    }
                }
            }
        }
        .slide-count {
            z-index: 1;
            position: absolute;
            bottom: 14px;
            right: 20px;
            display:inline-block;
            border-radius:32px;
            height:32px;
            padding:0 16px 0 7px;
            background:rgba(0,0,0,0.35);
            color:#fff;
            font-size: 14px;
            line-height:30px;
            strong {
                font-weight: 400;
            }
            span {
                opacity: 0.7;
            }
        }
        .btn-play {
            z-index: 1;
            position: relative;
            top: initial;
            right: initial;
            display: inline-block;
            transform: initial;
            width:27px;
            height:27px;
            opacity: 1;
            background: no-repeat center center / 15px auto url('/lg5-common/images/CS/icon/icon_video_pause.svg');
            &.pause {
                background: no-repeat center center / 15px auto url('/lg5-common/images/CS/icon/icon_video_play.svg');
            }
        }
        .btn-prev {
            position: relative;
            top: -1px;
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 3px;
            &::before {
                content: '';
                display: block;
                width: 16px;
                height: 16px;
                background-size: 16px;
                background-repeat: no-repeat;
                background-image: url(/lg5-common/images/icons/btn-arr-20x20-lt.svg);
            }
        }
        .btn-next {
            position: relative;
            top: -1px;
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-left: 3px;
            &::before {
                content: '';
                display: block;
                width: 16px;
                height: 16px;
                background-size: 16px;
                background-repeat: no-repeat;
                background-image: url(/lg5-common/images/icons/btn-arr-20x20-rt.svg);
            }
        }
    }
}

// 가전 꿀팀 목록
.popular-list-wrap {
    max-width: 1380px;
    margin: 28px auto 0;
    @media screen and (max-width: 1412px){
        padding: 0 16px;
    }
    @include tablet {
        padding: 0 16px;
    }
    ul {
        li {
            padding: 24px 8px 24px 8px;
            border-bottom: 1px solid #eee;
            &:first-child{
                padding-top: 0;

            }
            &:last-child{
                padding-bottom: 0;
                border-bottom: none;
            }
            a {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                &::after {
                    content: "";
                    position: absolute;
                    top: calc(50% - 10px);
                    right: 8px;
                    width: 20px;
                    height: 20px;
                    background: url(/lg5-common/images/icons/btn-arr-24x24-black.svg) no-repeat center center;
                    background-size: 14px auto;
                }
                p {
                    flex: 1;
                    color: #666;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    text-align: left;
                    padding: 0;
                    &:first-of-type {
                        flex:0;
                        flex-basis: 140px;
                    }
                    &:not(:first-of-type) {
                        margin-left: 10px;
                    }
                    @include tablet {
                        &:first-of-type {
                            flex:0;
                            flex-basis: initial;
                        }
                        
                    }
                }
            }

            .icon-movie {
                display: inline-block;
                width:25px;
                height:25px;
                background:url(/lg5-common/images/CS/icon/icon_movie.svg) no-repeat;
                background-size:25px;
                margin-left: 8px;
                vertical-align: middle;
            }
            .icon {
                display: flex;
                justify-content: center;                
                align-items: center;
                width: 80px;
                height: 80px;
                border-radius: 80px;
                background-color: #f9f9f9;
                padding: 8px;

                img {
                    width: 42px;
                    height: auto;
                }
            }
            .date {
                margin-right: 68px;
                @include tablet {
                    margin-right: 30px;
                }
            }
            .type {
                font-weight: 700;
                color: #0f0f0f;
            }
            .title {
                flex-basis: 60%;
                @include textEllipsisClamp(2);
                @include tablet {
                    flex-basis: 40%;
                }
            }
        }
    }
}

// 검색 영역
.keyword-search-wrap {
    &.keyword-search  {
        max-width: 1380px;
        margin: 28px auto 0 auto;
        padding: 0;
        @media screen and (max-width: 1412px){
            padding: 0 16px;
        }
        @include tablet {
            padding: 0 16px;
        }
        &.on {
            .search-box .search-input input[type=text] {
                border: none;
            }
            .search-recommend {
                display: none;
            }
        }
        .search-box {
            position: relative;
            max-width: 100%;
            background-color: #f6f6f6;
            border-radius: 8px;
            padding: 40px 0 40px;

            .search-title {
                padding-bottom: 24px;
                strong {
                    display: block;
                    font-size: 20px;
                    line-height: 25px;
                    font-weight: 700;
                    text-align: center;
                }
            }
            .search-input {
                padding: 0;
                max-width: 532px;
                input[type=text] {
                    font-size: 15px;
                    line-height: 22px;
                    background-color: white;
                    border: 1px solid #eee;
                    padding: 13px 70px 13px 20px;
                    box-shadow: none;
                    &::placeholder{
                        color:#666;
                    }
                }
            }
            .btn-search {
                right: 30px;
                width: 30px;
                height: 100%;
            }
            .btn-delete {
                right: 65px;
            }
        }
        .search-recommend {
            position: absolute;
            left: calc(50% - (532px /2));
            top: 87px;
            height: 52px;
            display: block;
            padding-left: 30px;
            margin-top: 0;
            .btn-recommend {
                position: relative;
                top: calc(50% - 10px);
                font-size: 15px;
                color: #666;
            }
        }

        .search-layer {
            top: 88px;
            right: initial;
            left: calc(50% - (532px /2));
            width: 532px;
            min-width: initial;
            border-radius: 25px;
            top: 89px;
        }
    }
}

// 보유제품
.own-product-wrap {
    min-width: 768px;
    max-width: 1380px;
    margin: 0  auto;
    @media screen and (max-width: 1412px){
        padding: 0 16px;
    }
    @include tablet {
        padding: 0 16px;
    }

    .product-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 12px;
        #popup-product-btn {
            position: relative;
            height: 46px;
            padding: 12px 24px;
            display: flex;
            align-items: center;
            &:hover {
                cursor: pointer;
            }
            span {
                line-height: 22px;
                font-size: 15px;
                font-weight: 500;
                &::before, &::after {
                    top: 11px;
                }
            }
        }
        em {
            color: $color-primary;
        }
    }
    .swiper-container {
        width: 100%;
        overflow: hidden;
        .swiper-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
        }
    }
    .swiper-controller {
        position: relative;
        .swiper-button-prev {
            position: absolute;
            top: 50%;
            left: -24px;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            &::before {
                content: ' ';
                display: block;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: white;
                background-repeat: no-repeat;
                background-size: 100%;
                box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.10);
                border: 1px solid #ddd;
            }
            &::after {
                position: absolute;
                top: 20px;
                left: 21px;
                content: '';
                display: inline-block;
                border: solid black;
                border-width: 0 1px 1px 0;
                padding: 4px;
                transform: rotate(135deg);
                -webkit-transform: rotate(135deg);
            }
            &.swiper-button-disabled {
                display: none;
            }
        }
        .swiper-button-next {
            position: absolute;
            top: 50%;
            right: -24px;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            &::before {
                content: ' ';
                display: block;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: white;
                background-repeat: no-repeat;
                background-size: 100%;
                box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.10);
                border: 1px solid #ddd;
            }
            &::after {
                position: absolute;
                top: 20px;
                right: 21px;
                content: '';
                display: inline-block;
                border: solid black;
                border-width: 0 1px 1px 0;
                padding: 4px;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
            }
            &.swiper-button-disabled {
                display: none;
            }
        }
    }
    .product-list {
        position: relative;
        display: flex;

        .product-item {
            flex-shrink: 0;
            width: calc(33.34% - 16px);
            font-size: 0;
            cursor: pointer;
            margin-right: 24px;

            // &:not(:last-of-type) {
            //     margin-right: 8px;
            // }
            
            &.open {
                button, a {
                    border: 1px solid #0f0f0f;
                    .model-ttl {
                        position: relative;
                        &::after {
                            content: '';
                            position: absolute;
                            top: calc(50% - 4px);
                            right: -4px;
                            display: inline-block;
                            border: solid #000;
                            border-width: 0 1px 1px 0;
                            padding: 4px;
                            transform: rotate(-45deg);
                            -webkit-transform: rotate(-45deg);
                        }
                    }
                }
            }
            
            button, a {
                position: relative;
                width: 99.8%;
                height: 100%;
                margin: 0 auto;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                text-align: left;
                border-radius: 16px;
                border: 1px solid #ddd;
                pointer-events: none;

                @include pc {
                    padding: 28px 16px 28px 16px;
                    min-height: 136px;
                }
                @include tablet {
                    padding: 16px 14px;
                    min-height: 100px;
                }

                &.btn-add {
                    border: 2px dotted #aaa;
                    pointer-events: initial;
                    span {
                        display: inline-block;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 26px;
                        margin: 0 auto;
                        &::before {
                            content: '';
                            display: block;
                            width: 32px;
                            height: 32px;
                            margin: 0 auto 8px;
                            background: url("/lg5-common/images/CS/icon/ic_addfile_24.svg") no-repeat center center / 100%;
                        }
                    }
                }
            }
            .model-ico {
                width: 68px;
                height: 68px;
                margin-right: 12px;
                img {
                    width: 100%;
                    height: auto;
                }
                @include tablet {
                    width: 50px;
                    height: 50px;
                }
            }
            .model-ttl {
                flex: 1;
                margin-right: 20px;
                .model-name {
                    color: #0f0f0f;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 26px;
                    @include textEllipsisClamp(2);

                    @include tablet {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
                .model-code {
                    display: block;
                    color: #666;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    margin-top: 4px;
                }
            }
        }
        .slick-arrow {
            &.slick-prev {
                left: -17px;
            }
            &.slick-next {
                right: -17px;   
            }
        }
    }

    .product-content {
        display: flex;
        justify-content: space-between;
        padding: 40px 0;
        border: 1px solid #0f0f0f;
        border-radius: 16px;
        margin-top: 20px;
        @include tablet {
            flex-direction: column;
            .lft-cont {
                padding-left: 20px !important;
                padding-right: 20px !important;
            }
            .rgt-cont {
                margin-top: 32px;
                padding-left: 20px !important;
                padding-right: 20px !important;
                &::before {
                    content: none !important;
                }
            }

            .supplies-wrap {
                border-top: 1px solid #eee;
                .supplies-title {
                    margin: 32px 0 20px;
                }
            }
        }

        .lft-cont {
            padding-left: 48px;
            padding-right: 40px;
        }
        .rgt-cont {
            flex: 1;
            overflow: hidden;
            padding-left: 40px;
            padding-right: 48px;
            position: relative;
            &::before {
                content: '';
                width: 1px;
                height: 100%;
                background-color: #eee;
                position: absolute;
                top: 0;
                left: 0;

            }
        }

        .home-links-wrap {
            padding: 0;
            width: 380px;

            @include tablet {
                width:initial;
            }

            ul {
                display: grid;
                grid-template-columns:repeat(4, 1fr);
                grid-gap: 30px 1rem;

                li {
                    min-width: 73px;
                    a {
                        font-size: 15px;
                        line-height: 22px;
                        &::before {
                            width: 70px;
                            height: 70px;
                            background-size: 40px;
                        }
                    }

                   
                }
            }
        }
        
        .supplies-wrap {
            .supplies-title-wrap {
                position: relative;
            }
            .supplies-title {
                color: #0f0f0f;
                font-weight: 700;
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 20px;
            }
            .supplies-btn {
                position: absolute;
                top: -2px;
                right: 0;
                text-decoration: none;
            }
            .supplies-list {
                ul {
                    display: flex;
                    overflow-x: auto;
                    overflow-y: hidden;
                    padding-bottom: 32px;
                    margin-right: -18px;
                    li {
                        position: relative;
                        display: inline-block;
                        width: 150px;
                        &~li {
                            margin-left: 18px;
                        }
                        
                        .prd-img {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 150px;
                            height: 150px;
                            position: relative;
                            text-align: center;
                            background-color: #fff;
                            a {
                                z-index: 1;
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                right: 0;
                            }
                            .flag {
                                position: absolute;
                                top: 6px;
                                right: 6px;
                                font-size: 12px;
                                line-height: 16px;
                                color: #0f0f0f;
                                border-radius: 4px;
                                // width: 45px;
                                height: 20px;
                                padding: 2px 6px;
                                text-align: center;
                                background-color: #e4e4e4;
                                text-decoration: none;
                                &.red {
                                    color: $color-primary;
                                    background-color: #ffeef3;
                                }
                            }
                            img {
                                width: 110px;
                                height: 110px;
                            }
                            &::after {
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                border-radius: 8px;
                                background-color: rgba(0, 0, 0, .03);
                            }
                        }
                        .prd-name {
                            color: #0f0f0f;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            margin-top: 10px;
                            @include textEllipsis;
                        }
                        .model-code {
                            color: #666;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            margin-top: 2px;
                        }
                        .price {
                            height: 24px;
                            color: #0f0f0f;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px; 
                            margin-top: 8px;
                        }
                        .cart {
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            .btn-cart {
                                display: inline-block;
                                width: 32px;
                                height: 32px;
                                border-radius: 4px;
                                background: url('/lg5-common/images/icons/icon-cart.svg') no-repeat #f9f9f9;
                                background-position: center center;
                                background-size: 20px auto;

                            }
                            .soldoutFlag {
                                display: inline-block;
                                vertical-align: middle;
                                font-size: 13px;
                                color: #fff;
                                border-radius: 4px;
                                background-color: #666;
                                padding: 3px 5px 4px 5px;
                                position: relative;
                                top: -2px;
                            }
                        }
                    }
                }
            }

        }

        .recommend-wrap {
            border-top:1px solid #eee;
            .recommend-title-wrap {
                position: relative;
            }
            .recommend-title {
                color: #0f0f0f;
                font-weight: 700;
                font-size: 18px;
                line-height: 26px;
                margin: 32px 0 20px;
            }
            .recommend-btn {
                position: absolute;
                top: -2px;
                right: 0;
                text-decoration: none;
            }
            .recommend-list {
                li {
                    a {
                        position: relative;
                        display: inline-block;
                        width: 100%;
                        color: #0f0f0f;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        padding-left: 44px;
                        padding-right: 20px;
                        overflow: hidden;
                        @include textEllipsis;

                    }
                    .flag {
                        position: absolute;
                        left: 0;
                        top: 0;
                        display: inline-block;
                        margin-right: 4px;
                        color: $color-primary;
                    }
                    .icon-movie {
                        position: absolute;
                        top: 1px;
                        right: 0;
                        display: inline-block;
                        width:24px;
                        height:24px;
                        background:url(/lg5-common/images/CS/icon/icon_movie.svg) no-repeat;
                        background-size:24px;
                        margin-left: 13px;
                        vertical-align: middle;
                    }
                    &:not(:first-of-type) {
                        margin-top: 12px;
                    }
                }

            }
        }
    }
}
// 보유제품 팝업
.popup-wrap {
    &.small {
        .pop-conts {
            padding-top: 26px;
        }
    }
    .pop-conts {
        max-height: 100%;
        padding: 32px 40px 30px 40px;
        .btn.btn-confirm {
            width: 100% !important;
            position: relative;
            span {
                &::after {
                    content: "";
                    display: inline-block;
                    position: relative;
                    top: 3px;
                    width: 16px;
                    height: 16px;
                    background: url(/lg5-common/images/icons/btn-arr-16-white.svg) no-repeat center center;
                    background-size: 16px auto;
                    margin-left: 2px;
                }
            }
        }
        .pop-tit {
            display: block;
            padding-bottom: 10px;
            margin-bottom: 16px;
            position: relative;
            color: #0f0f0f;
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;
            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                position: relative;
                top: 11px;
                background: #0f0f0f;
            }
        }
    }
}


// 베스트케어
.bestcare-section {
    min-width: 768px;
    max-width: 1380px;
    margin: 0  auto;
    @media screen and (max-width: 1412px){
        padding: 0 16px;
    }
    @include tablet {
        padding: 0 16px;
    }
}


// 다시 예약 팝업
#reReservationPopup {
    .pop-conts {
        padding-bottom: 0px;
    }
    .pop-footer {
        padding: 48px 40px 48px;
        .btn-group {
            .btn {
                padding: 14px 0;
                border-radius: 6px;
                font-size: 14px;
                line-height: 20px;
                width: calc(50% - 4px);
            }
        }
    }
    .pop-re-reserv {
        dl {
            display: flex;
            flex-direction: column;
            dt {
                font-size: 16px;
                font-weight: 700;
                line-height: 22px;
                text-align: center;
                // BTOCSITE-82900 서비스 재접수 - 팝업 버튼 겹침
                padding-right: 28px;
                em {
                    color: $color-primary;
                }
            }
            dd {
                margin-top: 20px;
            }
        }
        .rdo-box {
            border: 1px solid #ddd;
            border-radius: 8px;
            padding: 32px 34px;
            &:not(:first-of-type) {
                margin-top: 12px;
            }
            label {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: #0F0F0F;
            }
            .txt-topic {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: $color-primary;
                margin-top: 6px;
            }
        }
    }
}
// 예약 일시 팝업 
#reservationTimePopup {
    .calendar-area.pc-renewal {
        @media screen and (min-width: #{$desktop-s-width + 1}) {
            display: initial;
            background-color: pink;
            
                .calendar-info {
                    display:block;
                    width:100%;
                    margin-bottom:40px;
                    padding-right:0;
                    .info {         
                        margin-top:16px;   
                        font-size:0;
                        & > li {
                            display:inline-block;
                            & + li {
                                margin-left:16px;
                            }
                        }
                    }
                    & + .calendar-box {
                        display:block;
                        width:100%;
                    }
                }
                .calendar-box {
                    .box-inner {
                        &.date {
                            width:45%;
                        }
                        &.time {
                            width:55%;
                            margin-left:0;
                            padding-left:24px;
                        }
                    }
                }
                
            
        }
    }
    
}

// BTOCSITE-83209 고객지원 > 메인 > 마이영역 수정
#servicePopup {
    button.btn-confirm {
        height: 48px;
        line-height: 20px;
        font-weight: 700;
        font-size: 14px;
        padding: 14px 31px;
    }
    .home-links-wrap {
        border-radius: 12px;
        border: 1px solid #ddd;
        padding: 24px;
        margin-top: 24px;
        .home-links-title {
            color: #0f0f0f;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 12px;
        }
        ul {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            justify-items: center;
            align-items: start;
            gap: 24px 8px;
            li {
                min-width: 73px;
                width: 73px;
                a {
                    position: relative;
                    display: block;
                    font-size: 15px;
                    line-height: 22px;
                    font-weight: 400;
                    text-align: center;
                    &:before {
                        content: '';
                        display: block;
                        width: 70px;
                        height: 70px;
                        border-radius: 56px;
                        margin: 0 auto 4px;
                        background-color: #f9f9f9;
                        background-repeat: no-repeat;
                        background-size: 40px;
                        background-position: 50% 50%;
                        margin-bottom: 12px;
                    }
                    &.icon-reserv {
                        &:before {
                            background-image: url('/lg5-common/images/CS/icon/icon_reservation_v2.svg');
                        }
                    }
                    &.icon-result {
                        &:before {
                            background-image: url('/lg5-common/images/CS/icon/icon_reserv_result_v2.svg');
                        }
                    }
                    &.icon-contact {
                        &:before {
                            background-image: url('/lg5-common/images/CS/icon/icon_contact_us_v2.svg');
                        }
                    }
                    &.icon-manual {
                        &:before {
                            background-image: url('/lg5-common/images/CS/icon/icon_manual_v2.svg');
                        }
                    }
                    &.icon-visit {
                        &:before {
                            background-image: url('/lg5-common/images/CS/icon/icon_visit_v2.svg');
                        }
                    }
                    &.icon-goods {
                        &:before {
                            background-image: url('/lg5-common/images/CS/icon/icon_goods_v2.svg');
                        }
                    }
                    &.icon-wash {
                        &:before {
                            background-image: url('/lg5-common/images/CS/icon/icon_wash_v2.svg');
                        }
                    }
                    &.icon-removal {
                        &:before {
                            background-image: url('/lg5-common/images/CS/icon/icon_removal_v2.svg');
                        }
                    }
                }
            }
        }
    }
}