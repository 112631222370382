@charset "UTF-8";

@import "../../utilities/variables";
@import "../../utilities/mixins";

/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}


.slick-dots {
    position: absolute;
    bottom:-44px;
    left:0;
    width:100%;
    text-align: center;
    font-size:0;
    li {
        $li : &;
        display: inline-block;
        padding:0 6px;
        font-size:0;
        vertical-align: middle;

        @include mobile {
            padding: 0 4px;
        }

        button {
            display: inline-block;
            width:12px;
            height:12px;
            border-radius:50%;
            border:0;
            background: #aaa;
            vertical-align: top;
            font-size:0;
            overflow:hidden;

            @include mobile {
                width:8px;
                height:8px;
            }
        }
        &.slick-active button {
            width:28px;
            border-radius:6px;
            background: $color-primary;

            @include mobile {
                width:18px;
            }
        }
    }
}

.slick-controls {
    position: absolute;
    bottom:-44px;
    width:100%;
    text-align: center;

    .slick-dot-container {
        font-size:0;
    }

    .slick-dots, .slick-dot-container {
        display: inline-block !important;
        position: static;
        vertical-align: middle;
        width:auto;
        margin-right:6px;
    }
}

.slick-arrow {
    display: inline-block;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    border-radius:50%;
    border:0;
    width:48px;
    height:48px;
    padding:4px;
    z-index: 1;
    &:before {
        content: ' ';
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: 100%;
        box-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.14);
    }

    &.slick-prev {
        left:0;
        &:before {
            background-image: url(/lg5-common/images/icons/btn-arr-40x40-lt.svg);
        }
    }

    &.slick-next {
        right:0;
        &:before {
            background-image: url(/lg5-common/images/icons/btn-arr-40x40-rt.svg);
        }
    }

    &.slick-disabled {
        display: none !important;
    }
    @include mobile {
        width:36px;
        height:36px;
        &::before {
            width:28px;
            height:28px;
        }
        &.prev {
            &::before {
                background-image:url('/lg5-common/images/icons/btn-arr-40x40-lt-m.svg');
            }
        }
        &.next {
            &::before {
                background-image:url('/lg5-common/images/icons/btn-arr-40x40-rt-m.svg');
            }
        }
    }
}